import request from '@/utils/request.js'

// 企业联系人
export function getCompanyUserListApi(data) {
  return request({
    url: '/doftec/admin-company/lm/list',
    method: 'post',
    data: data
  })
}
// 企业联系人-修改
export function getCompanyUserUpdateApi(data) {
  return request({
    url: '/doftec/admin-company/lm/update.u',
    method: 'post',
    data: data
  })
}

// 企业联系人 - 删除 操作状态：delete 删除 push 推送 download 下载
export function getCompanyUserDeleteApi(data) {
  return request({
    url: '/doftec/admin-company/lm/action',
    method: 'post',
    data: data
  })
}



// 企业列表
export function getCompanyListApi(data) {
  return request({
    url: '/doftec/admin-company/list',
    method: 'post',
    data: data
  })
}

// 企业列表---新增
export function getCompanyCreateApi(data) {
  return request({
    url: '/doftec/admin-company/create',
    method: 'post',
    data: data
  })
}
// 企业列表---编辑
export function getCompanyUpdateApi(data) {
  return request({
    url: '/doftec/admin-company/update.u',
    method: 'post',
    data: data
  })
}

// 企业列表---删除 操作状态：delete 删除 push 推送 download 下载
export function getCompanyDeleteApi(data) {
  return request({
    url: '/doftec/admin-company/action',
    method: 'post',
    data: data
  })
}


// 小程序企业列表 
export function getCompanyTempListApi(data) {
  return request({
    url: '/doftec/admin-company/temp/list',
    method: 'post',
    data: data
  })
}
// 小程序企业列表-新增 
export function getCompanyTempCreateApi(data) {
  return request({
    url: '/doftec/admin-company/temp/create',
    method: 'post',
    data: data
  })
}
// 小程序企业列表 -删除
export function getCompanyTempDeleteApi(data) {
  return request({
    url: '/doftec/admin-company/temp/action',
    method: 'post',
    data: data
  })
}
// 小程序企业列表 -修改
export function getCompanyTempUpdateApi(data) {
  return request({
    url: '/doftec/admin-company/temp/update.u',
    method: 'post',
    data: data
  })
}
// 小程序企业列表 - 详情
export function getCompanyTempDetailApi(id,params) {
  return request({
    url: '/doftec/admin-company/getTemp/'+id,
    method: 'get',
    params: params
  })
}

// 产业链企业
export function getCompanyChainListApi(data) {
  return request({
    url: '/doftec/admin-company/chain/list',
    method: 'post',
    data: data
  })
}

// 产业链企业---新增
export function getCompanyChainCreateApi(data) {
  return request({
    url: '/doftec/admin-company/chain/create',
    method: 'post',
    data: data
  })
}
// 产业链企业---编辑
export function getCompanyChainUpdateApi(data) {
  return request({
    url: '/doftec/admin-company/chain/update.u',
    method: 'post',
    data: data
  })
}

// 产业链企业---删除 操作状态：delete 删除 push 推送 download 下载
export function getCompanyChainDeleteApi(data) {
  return request({
    url: '/doftec/admin-company/chain/action',
    method: 'post',
    data: data
  })
}


// 企业组
export function getCompanyGroupListApi(data) {
  return request({
    url: '/doftec/admin-company/group/list',
    method: 'post',
    data: data
  })
}

// 企业组---新增
export function getCompanyGroupCreateApi(data) {
  return request({
    url: '/doftec/admin-company/group/create',
    method: 'post',
    data: data
  })
}
// 企业组---编辑
export function getCompanyGroupUpdateApi(data) {
  return request({
    url: '/doftec/admin-company/group/update.u',
    method: 'post',
    data: data
  })
}

// 企业组---删除 操作状态：delete 删除 push 推送 download 下载
export function getCompanyGroupDeleteApi(data) {
  return request({
    url: '/doftec/admin-company/group/action',
    method: 'post',
    data: data
  })
}

// 企业组---导入
export function getCompanyGroupExportApi(id,data) {
  return request({
    url: '/doftec/admin-company/group/export.u?id='+id,
    method: 'post',
    data: data
  })
}

// 企业组---设置企业标签
export function setGroupTagApi(data) {
  return request({
    url: '/doftec/admin-company/group/updateLabel',
    method: 'post',
    data: data
  })
}


// 企业详情接口
export function getCompanyDetailApi(params) {
  return request({
    url: '/doftec/admin-company/getByUscc',
    method: 'get',
    params: params
  })
}
// 根据企业填报问卷列表接口
export function getCompanyServeyListApi(data) {
  return request({
    url: '/doftec/admin-survey/getWjListByUscc/list',
    method: 'post',
    data: data
  })
}

// 根据企业组发送短信，企业组id拼接字符串,逗号隔开
export function sendCompanySmsApi(params) {
  return request({
    url: '/doftec/admin/sendVariable',
    method: 'get',
    params: params
  })
}