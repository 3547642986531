<template>
  <div class="content">
    <el-card class="box-card">
      <div
        slot="header"
        class="clearfix"
      >
        <span class="title">企业信息</span>
      </div>
      <div class="company-content">
        <el-descriptions :colon="false">
          <el-descriptions-item label="企业名称:">{{companyDetail.name}}</el-descriptions-item>
          <el-descriptions-item label="信用代码:">{{companyDetail.uscc}}</el-descriptions-item>
          <el-descriptions-item label="海关代码:">{{companyDetail.customsCode}}</el-descriptions-item>
          <el-descriptions-item label="所在地:">{{companyDetail.provinceName}}{{companyDetail.cityName}}{{companyDetail.countyName}}</el-descriptions-item>
          <el-descriptions-item label="主要联系人:">{{companyDetail.lmName}}</el-descriptions-item>
          <el-descriptions-item label="企业标签:">{{companyDetail.type | companyType}}</el-descriptions-item>
          <el-descriptions-item>
            <el-tag
              style="margin-right:10px"
              size="small"
            >{{companyDetail.wjqValue !== 'K'?'十大企业':'其他企业' }}</el-tag>
          </el-descriptions-item>
        </el-descriptions>

      </div>
    </el-card>
    <el-card
      class="box-card"
      style="margin-top:10px"
    >
      <div
        slot="header"
        class="clearfix"
      >
        <span class="title">问卷填报情况</span>
      </div>
      <div class="report-content">
        <el-row :gutter="12">
          <el-col :span="8">
            <el-card
              shadow="never"
              class="card-box"
            >
              <div class="report-title">参与问卷数</div>
              <div class="report-value">{{pageparm.total}}</div>
              <!-- <el-statistic group-separator=","  :value="reportValue" title="参与问卷数"></el-statistic> -->
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card
              shadow="never"
              class="card-box"
            >
              <div class="report-title">近六个月填报次数</div>
              <div class="report-value">{{companyDetail.activityNumber?companyDetail.activityNumber:0}}</div>
              <!-- <el-statistic group-separator="," :precision="2" :value="sixReportValue" title="近六个月填报次数"></el-statistic> -->
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card
              shadow="never"
              class="card-box"
            >
              <div class="report-title">活跃度</div>
              <div class="report-value">{{companyDetail.activityNumber > 2 ? '活跃' : '不活跃'}}</div>
              <!-- <el-statistic group-separator="," :precision="2" :value="activityValue" title="活跃度"></el-statistic> -->
            </el-card>
          </el-col>
        </el-row>
        <div class="report-list">
          填报记录
        </div>
        <el-table
          :data="reportData"
          border
          style="width: 100%"
        >
          <el-table-column
            fixed
            prop="date"
            label="编号"
            type="index"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="wjdo_title"
            label="问卷名称"
          >
          </el-table-column>
          <el-table-column
            prop="time"
            label="填报时间"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
              >填报数据</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div>
          <Pagination
            v-bind:child-msg="pageparm"
            @callFather="callFather"
          ></Pagination>
        </div>
      </div>
    </el-card>

  </div>
</template>
<script>
import Pagination from '@/components/Pagination'
import { getCompanyDetailApi, getCompanyServeyListApi } from '@/api/company'
export default {
  // 注册组件
  components: {
    Pagination
  },
  data(){
    return{
       pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: 20
      },
      companyDetail:{},
      reportValue:5,
      sixReportValue:5,
      activityValue:'活跃',
      reportData:[]
    }
  },
  created() {
    this.getCompanyDetailFun()
    this.getCompanyServeyListFun()
  },
  methods:{
    // 获取企业详情
    async getCompanyDetailFun(){
      var params={
        uscc: this.$route.query.uscc
      }
      var res = await getCompanyDetailApi(params)
      if(res.code === 200){
        this.companyDetail = res.data
      }
      console.log('企业详情', res);
    },
    // 企业填报问卷列表
    async getCompanyServeyListFun(currentPage, pageSize){
      var start = currentPage ? currentPage * pageSize - pageSize : 0
      var params = {
          start: start,
          length: 10,
          draw: 1,
          orderStr:'time desc',
          uscc:this.$route.query.uscc
      }
      var res = await getCompanyServeyListApi(params)
      console.log('res', res);
      this.reportData = res.data
      this.pageparm.total = res.recordsTotal
    },
    handleClick(row){
      // 跳转本次填报数据页面
      this.$router.push({
        path:'/workbench/thisReportting',
        query:{
          id:row.wjdo_id,
          uscc:this.$route.query.uscc,
          name:'公司名称'
        }
      })

    },
      // 分页插件事件
    callFather(parm) {
      this.getCompanyServeyListFun(parm.currentPage, parm.pageSize)
    },
  }
}
</script>
<style scoped>
.content {
  width: 1200px;
  margin: 0 auto;
}
.card-box {
  height: 95px;
}
.report-title {
  color: #999999;
  font-size: 14px;
}
.report-value {
  font-weight: bold;
  font-size: 18px;
  color: #000;
  margin-top: 10px;
}
.report-list {
  margin: 15px;
  font-size: 14px;
  font-weight: bold;
}
.title {
  font-size: 15px;
  font-weight: bold;
}
</style>